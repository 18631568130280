import {useLocation, useMatches} from '@remix-run/react';
import type {
  MenuItem,
  Menu,
  MoneyV2,
  Country,
  CountryCode,
  Language,
  LanguageCode,
  Localization,
} from '@shopify/hydrogen/storefront-api-types';

// @ts-expect-error types not available
import typographicBase from 'typographic-base';
import {
  ATTR_LOADING_EAGER,
  BREAKPOINTS_SIZES,
  DEFAULT_GRID_IMG_LOAD_EAGER_COUNT,
  RETINA_SCALE,
} from './const';
import {I18nDictionary, I18nLocale} from './type';

export interface EnhancedMenuItem extends MenuItem {
  to: string;
  target: string;
  isExternal?: boolean;
  items: EnhancedMenuItem[];
}

export interface EnhancedMenu extends Menu {
  items: EnhancedMenuItem[];
}

export function missingClass(string?: string, prefix?: string) {
  if (!string) {
    return true;
  }

  const regex = new RegExp(` ?${prefix}`, 'g');
  return string.match(regex) === null;
}

export function formatText(input?: string | React.ReactNode) {
  if (!input) {
    return;
  }

  if (typeof input !== 'string') {
    return input;
  }

  return typographicBase(input, {locale: 'en-us'}).replace(
    /\s([^\s<]+)\s*$/g,
    '\u00A0$1',
  );
}

export function getExcerpt(text: string) {
  const regex = /<p.*>(.*?)<\/p>/;
  const match = regex.exec(text);
  return match?.length ? match[0] : text;
}

export function isNewArrival(date: string, daysOld = 30) {
  return (
    new Date(date).valueOf() >
    new Date().setDate(new Date().getDate() - daysOld).valueOf()
  );
}

export function isDiscounted(price: MoneyV2, compareAtPrice: MoneyV2) {
  if (compareAtPrice?.amount > price?.amount) {
    return true;
  }
  return false;
}

function resolveToFromType(
  {
    customPrefixes,
    pathname,
    type,
  }: {
    customPrefixes: Record<string, string>;
    pathname?: string;
    type?: string;
  } = {
    customPrefixes: {},
  },
) {
  if (!pathname || !type) return '';

  /*
    MenuItemType enum
    @see: https://shopify.dev/api/storefront/unstable/enums/MenuItemType
  */
  const defaultPrefixes = {
    BLOG: 'blogs',
    COLLECTION: 'collections',
    COLLECTIONS: 'collections', // Collections All (not documented)
    FRONTPAGE: 'frontpage',
    HTTP: '',
    PAGE: 'pages',
    CATALOG: 'collections/all', // Products All
    PRODUCT: 'products',
    SEARCH: 'search',
    SHOP_POLICY: 'policies',
  };

  const pathParts = pathname.split('/');
  const handle = pathParts.pop() || '';
  const routePrefix: Record<string, string> = {
    ...defaultPrefixes,
    ...customPrefixes,
  };

  switch (true) {
    // special cases
    case type === 'FRONTPAGE':
      return '/';

    case type === 'ARTICLE': {
      const blogHandle = pathParts.pop();
      return routePrefix.BLOG
        ? `/${routePrefix.BLOG}/${blogHandle}/${handle}/`
        : `/${blogHandle}/${handle}/`;
    }

    case type === 'COLLECTIONS':
      return `/${routePrefix.COLLECTIONS}`;

    case type === 'SEARCH':
      return `/${routePrefix.SEARCH}`;

    case type === 'CATALOG':
      return `/${routePrefix.CATALOG}`;
    case type === 'HTTP':
      return pathParts.concat(handle).join('/');

    // common cases: BLOG, PAGE, COLLECTION, PRODUCT, SHOP_POLICY, HTTP
    default:
      return routePrefix[type]
        ? `/${routePrefix[type]}/${handle}`
        : `/${handle}`;
  }
}

/*
  Parse each menu link and adding, isExternal, to and target
*/
function parseItem(customPrefixes = {}) {
  return function (item: MenuItem): EnhancedMenuItem {
    if (!item?.url || !item?.type) {
      // eslint-disable-next-line no-console
      console.warn('Invalid menu item.  Must include a url and type.');
      // @ts-ignore
      return;
    }

    // extract path from url because we don't need the origin on internal to attributes
    const {pathname} = new URL(item.url);

    /*
      Currently the MenuAPI only returns online store urls e.g — xyz.myshopify.com/..
      Note: update logic when API is updated to include the active qualified domain
    */
    const isInternalLink = /\.myshopify\.com/g.test(item.url);

    const parsedItem = isInternalLink
      ? // internal links
        {
          ...item,
          isExternal: false,
          target: '_self',
          to: resolveToFromType({type: item.type, customPrefixes, pathname}),
        }
      : // external links
        {
          ...item,
          isExternal: true,
          target: '_blank',
          to: item.url,
        };

    return {
      ...parsedItem,
      items: item.items?.map(parseItem(customPrefixes)),
    };
  };
}

/*
  Recursively adds `to` and `target` attributes to links based on their url
  and resource type.
  It optionally overwrites url paths based on item.type
*/
export function parseMenu(menu: Menu, customPrefixes = {}): EnhancedMenu {
  if (!menu?.items) {
    // eslint-disable-next-line no-console
    console.warn('Invalid menu passed to parseMenu');
    // @ts-ignore
    return menu;
  }

  return {
    ...menu,
    items: menu.items.map(parseItem(customPrefixes)),
  };
}

export const INPUT_STYLE_CLASSES =
  'appearance-none rounded dark:bg-transparent border focus:border-primary/50 focus:ring-0 w-full py-2 px-3 text-primary/90 placeholder:text-primary/50 leading-tight focus:shadow-outline';

export const getInputStyleClasses = (isError?: string | null) => {
  return `${INPUT_STYLE_CLASSES} ${
    isError ? 'border-red-500' : 'border-primary/20'
  }`;
};

export function statusMessage(status: string) {
  const translations: Record<string, string> = {
    ATTEMPTED_DELIVERY: 'Attempted delivery',
    CANCELED: 'Canceled',
    CONFIRMED: 'Confirmed',
    DELIVERED: 'Delivered',
    FAILURE: 'Failure',
    FULFILLED: 'Fulfilled',
    IN_PROGRESS: 'In Progress',
    IN_TRANSIT: 'In transit',
    LABEL_PRINTED: 'Label printed',
    LABEL_PURCHASED: 'Label purchased',
    LABEL_VOIDED: 'Label voided',
    MARKED_AS_FULFILLED: 'Marked as fulfilled',
    NOT_DELIVERED: 'Not delivered',
    ON_HOLD: 'On Hold',
    OPEN: 'Open',
    OUT_FOR_DELIVERY: 'Out for delivery',
    PARTIALLY_FULFILLED: 'Partially Fulfilled',
    PENDING_FULFILLMENT: 'Pending',
    PICKED_UP: 'Displayed as Picked up',
    READY_FOR_PICKUP: 'Ready for pickup',
    RESTOCKED: 'Restocked',
    SCHEDULED: 'Scheduled',
    SUBMITTED: 'Submitted',
    UNFULFILLED: 'Unfulfilled',
  };
  try {
    return translations?.[status];
  } catch (error) {
    return status;
  }
}

/**
 * Errors can exist in an errors object, or nested in a data field.
 */
export function assertApiErrors(data: Record<string, any> | null | undefined) {
  const errorMessage = data?.customerUserErrors?.[0]?.message;
  if (errorMessage) {
    throw new Error(errorMessage);
  }
}
// TODO Remove
// export const DEFAULT_LOCALE: I18nLocale = Object.freeze({
//   ...countries.default,
//   pathPrefix: '',
// });

// TODO Remove
// export function getLocaleFromRequest(request: Request): I18nLocale {
//   const url = new URL(request.url);
//   const firstPathPart =
//     '/' + url.pathname.substring(1).split('/')[0].toLowerCase();

//   return countries[firstPathPart]
//     ? {
//         ...countries[firstPathPart],
//         pathPrefix: firstPathPart,
//       }
//     : {
//         ...countries['default'],
//         pathPrefix: '',
//       };
// }

export function useIsHomePath() {
  const {pathname} = useLocation();
  const [root] = useMatches();
  const selectedLocale = root.data?.i18n;
  const strippedPathname = pathname.replace(selectedLocale?.pathPrefix, '');
  return strippedPathname === '/';
}

/**
 * Validates that a url is local
 * @param url
 * @returns `true` if local `false`if external domain
 */
export function isLocalPath(url: string) {
  try {
    // We don't want to redirect cross domain,
    // doing so could create fishing vulnerability
    // If `new URL()` succeeds, it's a fully qualified
    // url which is cross domain. If it fails, it's just
    // a path, which will be the current domain.
    new URL(url);
  } catch (e) {
    return true;
  }

  return false;
}

// TRANSLATIONS -------------------------
export function getI18nLocale({
  pathName,
  localization,
  defaultCountryCode,
  defaultLanguageCode,
}: {
  pathName: string;
  localization: Localization;
  defaultCountryCode: CountryCode;
  defaultLanguageCode: LanguageCode;
}): I18nLocale {
  const defaultCountry = localization?.availableCountries?.find(
    (country) => country.isoCode === defaultCountryCode,
  );

  const i18nLocale: I18nLocale = {
    notFound: false,
    countryCode: defaultCountryCode,
    languageCode: defaultLanguageCode,
    currency: defaultCountry.currency.isoCode,
    pathPrefix: '',
    language: defaultLanguageCode,
    country: defaultCountryCode,
  };

  const localizationMatch = /([a-z]{2})-([a-z]{2})(\/|$)/i.exec(pathName);
  const languageCodeFromUrl = localizationMatch && localizationMatch[1];
  const countryCodeFromUrl = localizationMatch && localizationMatch[2];

  if (!localizationMatch || !languageCodeFromUrl || !countryCodeFromUrl) {
    // NOTE: return default locale since there is not match and
    // we are navigating without path prefix
    return i18nLocale;
  }

  const countryFromUrl = localization?.availableCountries?.find(
    (country) => country.isoCode === countryCodeFromUrl.toUpperCase(),
  );

  // There is country in url but it is not available
  if (!countryFromUrl) {
    return {...i18nLocale, notFound: true};
  }

  //Check if language is available for country
  const languageFromUrl = countryFromUrl.availableLanguages.find(
    (language) => language.isoCode === languageCodeFromUrl.toUpperCase(),
  );
  if (!languageFromUrl) {
    return {...i18nLocale, notFound: true};
  }

  // Both language and country are available
  return {
    ...i18nLocale,
    notFound: false,
    countryCode: countryFromUrl.isoCode,
    languageCode: languageFromUrl.isoCode,
    currency: countryFromUrl.currency.isoCode,
    // NOTE: we can't use getPathPrefix here because in server.ts fetch function, env is not defined yet
    pathPrefix: `/${languageFromUrl.isoCode.toLowerCase()}-${countryFromUrl.isoCode.toLowerCase()}`,
    language: languageFromUrl.isoCode,
    country: countryFromUrl.isoCode,
  };
}

// NOTE: if there isn't pathPrfix we've to return '' instead of '/'
export function getPathPrefix({
  countryCode,
  languageCode,
}: {
  countryCode: CountryCode;
  languageCode: LanguageCode;
}): string {
  if (!countryCode || !languageCode) {
    return '';
  }
  if (
    countryCode === env.PUBLIC_DEFAULT_COUNTRY_CODE &&
    languageCode === env.PUBLIC_DEFAULT_LANGUAGE_CODE
  ) {
    return '';
  }
  return `/${languageCode.toLowerCase()}-${countryCode.toLowerCase()}`;
}

export function usePrefixPathWithLocale(path: string) {
  const [root] = useMatches();
  const selectedLocale = root.data?.i18n;

  return `${selectedLocale?.pathPrefix}${
    path.startsWith('/') ? path : '/' + path
  }`;
}

export function translate({
  dictionary,
  t,
  data,
}: {
  dictionary: I18nDictionary;
  t: string;
  data?: {[key: string]: string};
}): any {
  // const [root] = useMatches();
  // const {dictionary}: {dictionary: I18nDictionary} = root?.data ?? {};
  if (!dictionary) {
    return t;
  }
  let translatedString: any;
  try {
    translatedString = t
      .split('.')
      .reduce((dict, prop) => dict[prop], dictionary);
  } catch (error) {
    return t;
  }

  if (typeof translatedString !== 'string') {
    return t;
  }
  if (!data) {
    return translatedString;
  }

  return Object.entries(data).reduce((acc, [key, value]) => {
    return acc.replace(`{${key}}`, value);
  }, translatedString);
}
// END TRANSLATIONS -------------------------

// IMAGES -------------------------

export function getImageLoadingPriority(
  index: number,
  maxEagerLoadCount = DEFAULT_GRID_IMG_LOAD_EAGER_COUNT,
) {
  return index < maxEagerLoadCount ? ATTR_LOADING_EAGER : undefined;
}

export function getSrcSetSizes(sizes: number[]): string {
  // REF: https://www.mydevice.io/#tab1
  // NOTE: we support 1x, 2x and 3x, 2.5 and 4x will have bigger images, but are not so common
  // and in any case there is a huge improvement in the size handling
  const retinaMultiplier = RETINA_SCALE;
  if (sizes.length === 0) {
    throw new Error("Sizes array can't be empty");
  }
  sizes = sizes.reverse();
  const breakpoints = [...BREAKPOINTS_SIZES].reverse();
  const mediaQuerySizes: string[] = [];
  breakpoints.forEach((breakpoint, index) => {
    mediaQuerySizes.push(
      `(min-width: ${breakpoint}em) and (max-resolution: 1dppx) ${sizes[index]}vw`,
    );

    mediaQuerySizes.push(
      `(min-width: ${breakpoint}em) and (max-resolution: 2.5dppx) ${
        Math.round((sizes[index] / 2) * retinaMultiplier * 100) / 100
      }vw`,
    );

    mediaQuerySizes.push(
      `(min-width: ${breakpoint}em) ${
        Math.round((sizes[index] / 3) * retinaMultiplier * 100) / 100
      }vw`,
    );
  });
  const fullMediaQuerySizes =
    mediaQuerySizes.join(',') +
    `, (max-resolution: 1dppx) ${
      sizes[sizes.length - 1]
    }vw, (max-resolution: 2.5dppx) ${
      Math.round((sizes[sizes.length - 1] / 2) * retinaMultiplier * 100) / 100
    }vw, ${
      Math.round((sizes[sizes.length - 1] / 3) * retinaMultiplier * 100) / 100
    }vw`;
  return fullMediaQuerySizes;
}
// END IMAGES -------------------------

export const SHOP_NAME = 'kocca';
export const PAGINATION_SIZE = 8;

// IMAGES -------------------------
export const DEFAULT_GRID_IMG_LOAD_EAGER_COUNT = 4;
export const ATTR_LOADING_EAGER = 'eager';

export const BREAKPOINTS_SIZES = [32, 48, 64, 80, 96];
export const BREAKPOINTS_UNIT = 'em';
export const RETINA_SCALE = 1.3;
// test con immagine LCP in PDP 1x: 18kB - 1.5x: 31kb - 2x: 46kB
export let SRC_SET_SIZES: number[] = [];

(function () {
  // SRC_SET_SIZES = [
  //   100, 200, 400, 520, 600, 800, 1200, 1400, 1600, 1800, 2000, 2400,
  // ];

  // NOTE: we want to calculate the most common breakpoints based on target device sizes
  // NOTE: we'll use 90% as cap since in most of the cases we'll have some margin
  const devicesBreakpointsAndSizes = [
    {device: 'Moto G4', width: 360, sizes: [33, 50, 90]},
    {device: 'Iphone plus', width: 414, sizes: [33, 50, 90]},
    {device: 'Ipad', width: 768, sizes: [10, 25, 33, 50, 90]},
    {device: 'Desktop', width: 1280, sizes: [10, 25, 33, 50, 75, 90]},
  ];

  const sizes: number[] = [];
  devicesBreakpointsAndSizes.forEach((breakpoint) => {
    breakpoint.sizes.forEach((size) => {
      // We consider the retina devices to optimize the sizes RETINA_SCALE (2X)
      sizes.push(Math.floor((breakpoint.width / 100) * size * RETINA_SCALE));
    });
    // console.log(sizes);
  });

  sizes.push(...[80, 150]);
  sizes.sort((a, b) => a - b);

  // TODO: we can remove duplicates or sizes in case the distance between
  // prev or next is < than a constant ex 100 200 220 we can remove 200

  // The output is this one and we can set it statically :)
  // [
  //   270, 486,  310,  558,
  //   380, 576, 1038,  480,
  //   633, 960, 1440, 1728
  // ]
  SRC_SET_SIZES = sizes;
})();

// END IMAGES -------------------------
